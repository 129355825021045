<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: labTests.vue
Description:This file gives the form where the lab reports data from the labs was filled by user.
-->
<template>
  <layout-pondlogs containerType="card-footer">
    <template slot="title">{{ $t("LabTst_lab_tests") }}</template>

    <template slot="header"
      ><lab-test-action-bar
        :tableColumns="tableColumns"
        @column-change="handleColumnChange"
    /></template>

    <div
      slot="layout-pondlogs-scrollable-main"
      :key="location._id"
      v-loading="loading"
      element-loading-background="white"
    >
      <lab-test-table
        :loading="loading"
        :tableColumns="tableColumns"
        :selectedColumns="selectedColumns"
      />
    </div>
  </layout-pondlogs>
</template>

<script>
import labTestActionBar from "@/components/culture/labTestActionBar";
import labTestTable from "@/components/culture/labTestTable";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters, mapActions } from "vuex";

import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
export default {
  components: { labTestActionBar, labTestTable },
  mixins: [errorHandlerMixin],
  data: function() {
    return {
      loading: false,
      selectedColumns: []
    };
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next();
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    ...mapGetters("pond", {
      getPonds: "getPonds"
    }),
    location: function() {
      return this.getCurrUserLocation;
    },
    tableColumns: function() {
      return {
        pond_id: {
          prop: "pond_id",
          label: this.$t("Comn_pond_name"),
          minWidth: 150,
          hasNestedColumns: false,
          align: "center",
          default: true
        },
        lab_id: {
          prop: "lab_id",
          label: this.$t("Resource_lab_name"),
          minWidth: 150,
          hasNestedColumns: false,
          default: true
        },
        ph: {
          prop: "ph",
          label: this.$t("Comn_ph"),
          // sub_label: this.$t("Lab_ph_limits"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: true
        },
        salinity: {
          prop: "salinity",
          label: this.$t("Comn_salinity_ppt"),
          // sub_label: this.$t("Lab_salinity_limits"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        do: {
          prop: "do",
          label: this.$t("Comn_do"),
          sub_label: this.$t("PM_dissolved_oxygen"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        temperature: {
          prop: "temperature",
          label: this.$t("Comn_temp"),
          sub_label: this.$t("Comn_temperature"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        co3: {
          prop: "co3",
          label: this.$t("Comn_co3"),
          sub_label: this.$t("PM_Carbonates"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: false
        },
        hco3: {
          prop: "hco3",
          label: this.$t("Comn_hco3"),
          sub_label: this.$t("PM_Bicarbonates"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: false
        },
        alkallnity_total: {
          prop: "alkallnity_total",
          label: this.$t("alkalinity"),
          // sub_label: this.$t("Lab_alkalinity_limits"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        hardness: {
          prop: "hardness",
          label: this.$t("Comn_hardness_ppm"),
          // sub_label: this.$t("Lab_hardness_limits"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        ammonia: {
          prop: "ammonia",
          label: "NH3 (ppm)",
          sub_label: this.$t("Comn_ammonia_ppm"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        ammonium: {
          prop: "ammonium",
          label: "NH4 (ppm)",
          sub_label: this.$t("Comn_ammonia_nh4"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        tan: {
          prop: "tan",
          label: "TAN",
          sub_label: this.$t("Comn_tan"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        iron: {
          prop: "iron",
          label: "Fe (ppm)",
          sub_label: this.$t("Comn_iron_ppm"),
          minWidth: 100,
          align: "center",
          hasNestedColumns: false,
          default: true
        },
        green_colonies: {
          prop: "green_colonies",
          label: this.$t("Comn_green_colonies"),
          sub_label: "",
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        yellow_colonies: {
          prop: "yellow_colonies",
          label: this.$t("Comn_yellow_colonies"),
          sub_label: "",
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        no2: {
          prop: "no2",
          label: this.$t("Comn_no2"),
          sub_label: this.$t("Comn_Nitrogen_Dioxide"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        calcium: {
          prop: "calcium",
          label: "Ca (ppm)",
          sub_label: this.$t("Comn_Calcium"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        magnesium: {
          prop: "magnesium",
          label: "Mg (ppm)",
          sub_label: this.$t("Comn_Magnesium"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        potassium: {
          prop: "potassium",
          label: "K (ppm)",
          sub_label: this.$t("Comn_Potassium"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        ntu: {
          prop: "ntu",
          label: "NTU",
          sub_label: this.$t("Comn_NTU"),
          minWidth: 100,
          hasNestedColumns: false,
          align: "center",
          default: false
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: 80,
          align: "left",
          hasNestedColumns: false,
          default: true
        }
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("resource", {
      fetchAllLabs: "fetchAllLabs"
    }),
    async initComponent() {
      try {
        this.loading = true;
        const payload = {
          location_id: "",
          get_all: true
        };
        await this.fetchAllLabs(payload);
        await this.fetchAllPonds({
          location_id: this.location._id,
          status: "ACTIVE",
          get_all: true
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleColumnChange(selectedColumns) {
      this.selectedColumns = selectedColumns;
      // console.log(selectedColumns);
      // const latestColumn = selectedColumns[selectedColumns.length - 1];
      // if (selectedColumns.includes("actions") && latestColumn !== "actions") {
      //   selectedColumns.pop();
      //   selectedColumns.pop();
      //   selectedColumns.push(latestColumn);
      //   selectedColumns.push("actions");
      // }

      // const columnObj = selectedColumns.reduce((acc, curr) => {
      //   acc[curr] = this.tableColumns[curr];
      //   return acc;
      // }, {});
      // this.selectedColumns = Object.assign({}, columnObj);
      // console.log(this.selectedColumns);
    }
  }
};
</script>

<style lang="scss">
.stock-medicine-view {
}
</style>
