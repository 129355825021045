<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: labTestTable.vue
Description: This file is the part of the labTest page. It contains the table component which is useful for taking input related to lab test reports
-->
<template>
  <el-row class="lab-test-table">
    <el-row v-if="!loading">
      <er-data-tables
        v-loading="loading"
        element-loading-background="white"
        type="white-header-table"
        :table-data="tableDataArrComputed"
        :el-table-props="tableProps"
        :selectedColumnHeaders="selectedColumns"
        :selectedColumnHeadersSortable="true"
        :action-column="false"
        layout="table"
        uniqueId="lab-test-table"
        :columns="tableColumns"
        :key="$i18n.locale"
      >
        <template v-slot:pond_id_header="{ header }">
          <span>{{ header.label }}</span>
        </template>
        <template v-slot:co3_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:hco3_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:do_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:temperature_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:no2_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:ammonia_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:ammonium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:tan_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:iron_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:magnesium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:calcium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:potassium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:ntu_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:pond_id="{ row }">
          <er-select
            v-model="row.data.pond_id"
            size="mini"
            :placeholder="$t('Ponds_select_pond_name')"
            @change="handleChangeInPond($event, row.$index)"
          >
            <el-option
              v-for="pond in getPondsInCulture"
              :disabled="getIsOptionAvailable(pond._id)"
              :key="pond._id"
              :value="pond._id"
              :label="pond.title"
            ></el-option>
          </er-select>
        </template>
        <template v-slot:lab_id="{ row }">
          <er-select
            v-model="row.data.lab_id"
            size="mini"
            :placeholder="$t('Resource_lab_name')"
          >
            <el-option
              v-for="{ _id, title } in getSortedLabs"
              :key="_id"
              :value="_id"
              :label="title"
            ></el-option>
          </er-select>
        </template>
        <template v-slot:ph="{ row }">
          <el-input-number
            v-model="row.data.ph"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:salinity="{ row }">
          <el-input-number
            v-model="row.data.salinity"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:do="{ row }">
          <el-input-number
            v-model="row.data.do"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:temperature="{ row }">
          <el-input-number
            v-model="row.data.temperature"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:co3="{ row }">
          <el-input-number
            v-model="row.data.co3"
            :controls="false"
            size="mini"
            :min="0"
            @change="onCo3Change(row.data)"
          />
        </template>
        <template v-slot:hco3="{ row }">
          <el-input-number
            v-model="row.data.hco3"
            :controls="false"
            size="mini"
            :min="0"
            @change="onHco3Change(row.data)"
          />
        </template>
        <template v-slot:alkallnity_total="{ row }">
          <el-input-number
            v-model="row.data.alkallnity_total"
            :controls="false"
            size="mini"
            :disabled="false"
          />
        </template>
        <template v-slot:hardness="{ row }">
          <el-input-number
            v-model="row.data.hardness"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:ammonia="{ row }">
          <el-input-number
            v-model="row.data.ammonia"
            :controls="false"
            size="mini"
            :min="0"
            @change="onNH3Change(row.data)"
          />
        </template>
        <template v-slot:ammonium="{ row }">
          <el-input-number
            v-model="row.data.ammonium"
            :controls="false"
            size="mini"
            :min="0"
             @change="onNH4Change(row.data)"
          />
        </template>
        <template v-slot:tan="{ row }">
          <el-input-number
            v-model="row.data.tan"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:iron="{ row }">
          <el-input-number
            v-model="row.data.iron"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:green_colonies="{ row }">
          <el-input-number
            v-model="row.data.green_colonies"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:yellow_colonies="{ row }">
          <el-input-number
            v-model="row.data.yellow_colonies"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:no2="{ row }">
          <el-input-number
            v-model="row.data.no2"
            :controls="false"
            size="mini"
          />
        </template>
        <template v-slot:calcium="{ row }">
          <el-input-number
            v-model="row.data.calcium"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:magnesium="{ row }">
          <el-input-number
            v-model="row.data.magnesium"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:potassium="{ row }">
          <el-input-number
            v-model="row.data.potassium"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:ntu="{ row }">
          <el-input-number
            v-model="row.data.ntu"
            :controls="false"
            size="mini"
            :min="0"
          />
        </template>
        <template v-slot:actions="{ row }">
          <template v-if="row.$index === tableDataLength - 1">
            <er-button
              btnType="add"
              type="text"
              size="mini"
              :showLabel="true"
              :showIcon="true"
              :disabled="isReadOnly"
              @click="handleAddRow()"
            ></er-button>
          </template>
          <template v-else>
            <er-button
              btnType="delete"
              type="text"
              :showLabel="true"
              :showIcon="true"
              size="mini"
              :disabled="isReadOnly"
              @click="handleDeleteRow(row.$index)"
            ></er-button>
          </template>
        </template>
      </er-data-tables>
      <el-row class="table-footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="btnloading"
          size="mini"
          :disabled="isReadOnly"
          @click="handleSaveTableData"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          size="mini"
          @click="handleCancel"
          :disabled="isReadOnly"
        ></er-button>
      </el-row>
    </el-row>
    <Loader v-else-if="loading"></Loader>
  </el-row>
</template>
<script>
import LabTest from "@/model/labtest";
import { mapGetters, mapActions } from "vuex";
import { validate, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Loader from "@/components/base/Loader";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
export default {
  name: "LabTestsTable",
  mixins: [errorHandlerMixin, datesHandlerMixin],
  props: {
    tableColumns: {
      default: () => []
    },
    selectedColumns: {
      default: () => []
    },
    loading: {
      default: false
    }
  },
  components: {
    Loader
  },
  data: function() {
    return {
      tableDataArr: [new LabTest()],
      tableProps: {
        size: "small",
        height: "calc(100vh - var(--table-gutter))"
      },
      permissionsData: {
        name: "labTests",
        keyToValidateAccess: "PERMISSIONS",
        PERMISSIONS: [permissions.MANAGE_LAB_TESTS]
      },
      btnloading: false,
      labRecords: [],
      selectPonds: []
    };
  },
  async mounted() {
    extend("required", {
      ...required,
      message: this.$t("Comn_field_is_required", {
        field: "{_field_}"
      })
    });
    extend("recordExisting", {
      validate: (record) => {
        return !this.isRecordAlreadyExisting(record);
      },
      message: (fieldName) => {
        return `${this.$t("Comn_record")} ${fieldName}`;
      }
    });
    extend("minmax", {
      validate: function(value, { min, max, name }) {
        return value >= min;
      },
      params: ["min", "max", "name"],
      message: (fieldName, placeholders) => {
        return this.$tc("Comn_must_greater_than", 1, {
          threshold: 0,
          field: fieldName
        });
      }
    });
    extend("minmaxPh", {
      validate: function(value, { min, max, name }) {
        return value >= min && value <= max;
      },
      params: ["min", "max", "name"],
      message: (fieldName, placeholders) => {
        return this.$tc("Comn_must_lie_between_thresholds", 1, {
          threshold1: placeholders.min,
          threshold2: placeholders.max,
          field: fieldName
        });
      }
    });
    extend("min", {
      validate: function(value, { length, name }) {
        return value >= length;
      },
      params: ["length", "name"],
      message: (fieldName, placeholders) => {
        return this.$tc("Comn_must_greater_than", 1, {
          threshold: 0,
          field: fieldName
        });
      }
    });
    await this.fetchLabTestsByLocation({
      location_id: this.getCurrUserLocation._id,
      date: `${this.getDate.slice(0, 10)}T00:00:00.000Z`,
      get_all: true
    });
  },
  watch: {
    async getDate(newDate, oldDate) {
      this.tableDataArr = [new LabTest()];
      this.selectPonds = [];
      this.initComponent();
    }
  },
  computed: {
    ...mapGetters("pond", {
      getPonds: "getPonds"
    }),
    ...mapGetters("resource", {
      getArrLabs: "getArrLabs",
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("labTests", {
      getDate: "getDate",
      getLocationLabTests: "getLocationLabTests"
    }),
    getSortedLabs() {
      return this.$lodash
        .cloneDeep(this.getArrLabs || [])
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    getTotlaAlkalinity() {
      // :value="row.data.co3 + row.data.hco3"
      return (data) => {
        return data?.co3 + data?.hco3;
      };
    },
    tableDataArrComputed() {
      return this.tableDataArr;
    },
    getDateObjForSelectedDate: function() {
      return this.dhm__dateUtilsLib.toDate(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(this.getDate || 1)),
          this.getUserTimeZoneString
        )
      );
    },
    getActivePonds() {
      return this.getPonds.filter(({ status }) => status === "ACTIVE");
    },
    getPondsInCulture() {
      return this.getActivePonds.filter(({ cultivation_date }) => {
        const stockingDate = this.dhm__castUserUTCToUTCDateObj(
          cultivation_date
        );
        return this.dhm__dateUtilsLib.isBefore(
          stockingDate,
          this.getDateObjForSelectedDate
        );
      });
    },
    tableDataLength: function() {
      return this.tableDataArr.length;
    }
  },
  methods: {
    ...mapActions("labTests", {
      addLabTests: "addLabTests",
      fetchLabTestsByLocation: "fetchLabTestsByLocation"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      await this.fetchLabTestsByLocation({
        location_id: this.getCurrUserLocation._id,
        date: `${this.getDate.slice(0, 10)}T00:00:00.000Z`,
        get_all: true
      });
    },
    getIsOptionAvailable(pond_id) {
      return this.selectPonds.includes(pond_id)
    },
    handleChangeInPond(pond_id, index) {
      if (!this.selectPonds.includes(pond_id)) {
        this.selectPonds[index] = pond_id;
      }
    },
    // onCo3Change(data) {
    //   // update the record for alklinity total
    //   data.alkallnity_total = data?.co3 + data?.hco3;
    // },
    // onHco3Change(data) {
    //   data.alkallnity_total = data?.co3 + data?.hco3;
    // },
    onNH3Change(data) {
      if (typeof data.ammonia === "number" && typeof data.ammonium !== "number") {
        data.tan = data.ammonia;
      } else if (
        typeof data.ammonia !== "number" &&
        typeof data.ammonium === "number"
      ) {
        data.tan = data.ammonium;
      } else if (
        typeof data.ammonia === "number" &&
        typeof data.ammonium === "number"
      ) {
        data.tan = (data.ammonia + data.ammonium).toFixed(2);
      } else {
        data.tan = undefined;
      }
    },
    onNH4Change(data) {
      if (typeof data.ammonium === "number" && typeof data.ammonia !== "number") {
        data.tan = data.ammonium;
      } else if (
        typeof data.ammonium !== "number" &&
        typeof data.ammonia === "number"
      ) {
        data.tan = data.ammonia;
      } else if (
        typeof data.ammonium === "number" &&
        typeof data.ammonia === "number"
      ) {
        data.tan = (data.ammonium + data.ammonia).toFixed(2);
      } else {
        data.tan = undefined;
      }
    },
    onCo3Change(data) {
      // Update alkalinity_total with a default value (10) if only co3 is defined
      if (typeof data.co3 === "number" && typeof data.hco3 !== "number") {
        data.alkallnity_total = data.co3; // Use 10 as default for hco3
      } else if (
        typeof data.co3 !== "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = data.hco3; // Use 10 as default for hco3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = (data.co3 + data.hco3).toFixed(2); // Normal addition if both are numbers
      } else {
        // Set alkalinity_total to undefined if neither is a number
        data.alkallnity_total = undefined;
      }
    },

    onHco3Change(data) {
      // Update alkalinity_total with a default value (10) if only hco3 is defined
      if (typeof data.co3 !== "number" && typeof data.hco3 === "number") {
        data.alkallnity_total = data.hco3; // Use 10 as default for co3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 !== "number"
      ) {
        data.alkallnity_total = data.co3; // Use 10 as default for co3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = (data.co3 + data.hco3).toFixed(2); // Normal addition if both are numbers
      } else {
        // Set alkalinity_total to undefined if neither is a number
        data.alkallnity_total = undefined;
      }
    },
    async handleCancel() {
      this.tableDataArr = [new LabTest()];
      await this.$emit("reload");
    },
    isRecordAlreadyExisting(currentRecord) {
      let isExitsing = false;
      const sameRecord = this.getLocationLabTests.filter(
        ({ lab_id, pond_id }) => {
          return (
            currentRecord.lab_id === lab_id._id &&
            currentRecord.pond_id === pond_id
          );
        }
      );
      if (sameRecord.length > 0) {
        isExitsing = true;
      }
      return isExitsing;
    },
    getValidationError(record, index) {
      console.log(record);

      const validations = {
        pond_id: {
          key: "pond_id",
          rules: "required",
          name: this.$tc("Comn_pond", 0)
        },
        lab_id: {
          key: "lab_id",
          rules: "required",
          name: this.$t("Comn_lab")
        },
        record: {
          key: "record",
          rules: "recordExisting",
          name: this.$t("Comn_already_exists")
        },
        ph: {
          key: "ph",
          rules: "minmaxPh:0.1,14",
          name: this.$t("Comn_ph")
        },
        salinity: {
          key: "salinity",
          rules: "minmax:0.1,60",
          name: this.$t("Comn_salinity")
        },
        do: {
          key: "do",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_do")
        },
        temperature: {
          key: "temperature",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_temp")
        },
        hardness: {
          key: "hardness",
          rules: "minmax:0.1,10000",
          name: this.$t("Comn_hardness_ppm")
        },
        ammonia: {
          key: "ammonia",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_ammonia_ppm")
        },
        ammonium: {
          key: "ammonium",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_ammonia_nh4")
        },
        tan: {
          key: "tan",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_tan")
        },
        iron: {
          key: "iron",
          rules: "minmax:0.1,100",
          name: this.$t("Comn_iron")
        },
        co3: {
          key: "co3",
          rules: "min:0.1",
          name: this.$t("Comn_co3")
        },
        hco3: {
          key: "hco3",
          rules: "min:0.1",
          name: this.$t("Comn_hco3")
        },
        alkallnity_total: {
          key: "alkallnity_total",
          rules: "min:0.1",
          name: this.$t("alkalinity")
        },
        green_colonies: {
          key: "green_colonies",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_green_colonies")
        },
        yellow_colonies: {
          key: "yellow_colonies",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_yellow_colonies")
        },
        no2: {
          key: "no2",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_no2")
        },
        calcium: {
          key: "calcium",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_Calcium")
        },
        magnesium: {
          key: "magnesium",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_Magnesium")
        },
        potassium: {
          key: "potassium",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_Potassium")
        },
        ntu: {
          key: "ntu",
          rules: "minmax:0.1,1000",
          name: this.$t("Comn_NTU")
        }
      };

      return Object.entries(validations).map(async ([key, value]) => {
        const field = key === "record" ? record : record[key];
        const valRes = await validate(field, value.rules, {
          name: value.name
        });

        if (!valRes.valid) {
          return {
            message: this.$t("item-in-row-no", {
              item: valRes.errors[0],
              no: index + 1
            })
          };
        }
      });
    },
    async validatePayload() {
      this.$gblUAMCanUserEdit(this.permissionsData);
      const errors = [];
      let errorData = [];
      const labRecordsLocal = this.tableDataArr;
      console.log("labRecordsLocal", labRecordsLocal, this.tableDataArr);
      await labRecordsLocal.forEach((record, index) => {
        record.date = this.getDate;
        // record.alkallnity_total =
        //   !record.co3 && record.co3 !== 0 && !record.hco3 && record.co3 !== 0
        //     ? undefined
        //     : record.co3 + record.hco3;

        errorData = [...errorData, ...this.getValidationError(record, index)];
      });
      const resolvedError = await Promise.all(errorData);
      resolvedError.forEach((err) => {
        if (err) {
          errors.push(err);
        }
      });

      return {
        labRecordsLocal,
        errors
      };
    },
    async handleSaveTableData() {
      this.btnloading = true;
      let payload = {};
      try {
        const payloadData = await this.validatePayload();
        console.log(payloadData);
        if (payloadData.errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: payloadData.errors
          };
        }

        const validRecords = payloadData.labRecordsLocal.filter((record) => {
          // Check for at least one non-required field with a value
          const { date, lab_id, pond_id, ...otherFields } = record;
          return Object.values(otherFields).some(
            (field) => field !== undefined
          );
        });

        if (validRecords.length === 0) {
          // Show error message: No valid records to save
          this.ehm__errorFailToSave([{ message: this.$t("No Valid data") }]);
          return;
        }

        payload = {
          lab_tests: payloadData.labRecordsLocal
        };
        await this.addLabTests(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Lab_tests_created_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Save" });
        this.initComponent();
        this.selectPonds = [];
        this.tableDataArr = [{ ...new LabTest() }];
      } catch (err) {
        const errors = [];
        if (err.errors && err.errors.length > 0) {
          this.ehm__errorMessages(err, true);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.btnloading = false;
      }
    },
    handleAddRow() {
      try {
        this.mixPanelEventGenerator({ eventName: "Lab Tests Table - Row Add" });
        this.tableDataArr.push(new LabTest());
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleDeleteRow(rowIndexPos) {
      this.selectPonds.splice(rowIndexPos, 1);
      this.tableDataArr.splice(rowIndexPos, 1);
    }
  }
};
</script>
<style lang="scss">
.lab-test-table {
  @include responsiveProperty(--table-gutter, 163px, 170px, 186px);
  .table-col-header-container {
    text-align: center;
  }
  .el-table .cell {
    .el-input-number {
      width: 60px;
    }
  }
  .er-data-tables .el-table .el-select {
    width: 130px !important;
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
}

.lab-test-table-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}

.lab-test-table-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
</style>
